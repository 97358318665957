import gql from 'graphql-tag';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type AccessToken = {
  __typename?: 'AccessToken';
  expires: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type CompleteParams = {
  age: Scalars['String']['input'];
  familiarity: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  purpose: Scalars['String']['input'];
};

export type Config = {
  __typename?: 'Config';
  callToAction?: Maybe<ConfigurationItem>;
  hook?: Maybe<ConfigurationItem>;
  retention?: Maybe<ConfigurationItem>;
};

export type ConfigurationAlternatives = {
  __typename?: 'ConfigurationAlternatives';
  callToActions?: Maybe<Array<Maybe<ConfigurationItem>>>;
  hooks?: Maybe<Array<Maybe<ConfigurationItem>>>;
  retentions?: Maybe<Array<Maybe<ConfigurationItem>>>;
};

export type ConfigurationItem = {
  __typename?: 'ConfigurationItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type CreateConfigParams = {
  callToAction: Scalars['String']['input'];
  hook: Scalars['String']['input'];
  retention: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  complete: Scalars['Boolean']['output'];
  createProject: Project;
  generateSpeech: Project;
  login: AccessToken;
  refreshAccessToken: AccessToken;
  removeProject: Scalars['Boolean']['output'];
  updateProject: Project;
};


export type MutationCompleteArgs = {
  params: CompleteParams;
};


export type MutationCreateProjectArgs = {
  config: CreateConfigParams;
  topic: Scalars['String']['input'];
};


export type MutationGenerateSpeechArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  voiceId: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  password?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};


export type MutationRemoveProjectArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateProjectArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  params: UpdateParams;
};

export type Project = {
  __typename?: 'Project';
  config?: Maybe<Config>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  script?: Maybe<Scalars['String']['output']>;
  speech?: Maybe<Speech>;
  timestamp?: Maybe<Scalars['Int']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  configurations: ConfigurationAlternatives;
  me: User;
  project: Project;
  projects: Array<Project>;
  voices: Array<Voice>;
};


export type QueryProjectArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};

export type Sample = {
  __typename?: 'Sample';
  duration?: Maybe<Scalars['Float']['output']>;
};

export type Speech = {
  __typename?: 'Speech';
  created?: Maybe<Scalars['Int']['output']>;
  expires?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  voice?: Maybe<Voice>;
};

export type UpdateParams = {
  name?: InputMaybe<Scalars['String']['input']>;
  script?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  age?: Maybe<Scalars['Int']['output']>;
  familiarity?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  purpose?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Int']['output']>;
  tokens?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<UserType>;
  username?: Maybe<Scalars['String']['output']>;
  wishlist?: Maybe<Scalars['Boolean']['output']>;
  work?: Maybe<Scalars['String']['output']>;
};

export enum UserType {
  Default = 'DEFAULT',
  Guest = 'GUEST'
}

export type Voice = {
  __typename?: 'Voice';
  description?: Maybe<Scalars['String']['output']>;
  gradient?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sample?: Maybe<Sample>;
};

export type GetLayoutInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLayoutInfoQuery = { __typename?: 'Query', me: { __typename?: 'User', type?: UserType | null, name?: string | null, username?: string | null, tokens?: number | null } };

export type CompleteMutationVariables = Exact<{
  params: CompleteParams;
}>;


export type CompleteMutation = { __typename?: 'Mutation', complete: boolean };

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshAccessToken: { __typename?: 'AccessToken', token: string } };

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AccessToken', token: string } };

export type ProjectFieldsFragment = { __typename?: 'Project', id: string, topic?: string | null, result?: string | null, name?: string | null, script?: string | null, timestamp?: number | null, config?: { __typename?: 'Config', hook?: { __typename?: 'ConfigurationItem', value?: string | null, id: string } | null, retention?: { __typename?: 'ConfigurationItem', id: string, value?: string | null } | null, callToAction?: { __typename?: 'ConfigurationItem', id: string, value?: string | null } | null } | null, speech?: { __typename?: 'Speech', id: string, created?: number | null, expires?: number | null, voice?: { __typename?: 'Voice', description?: string | null, gradient?: string | null, name?: string | null, id: string } | null } | null };

export type GetProjectQueryVariables = Exact<{
  projectId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetProjectQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, type?: UserType | null }, voices: Array<{ __typename?: 'Voice', id: string, name?: string | null, description?: string | null, gradient?: string | null, sample?: { __typename?: 'Sample', duration?: number | null } | null }>, project: { __typename?: 'Project', id: string, topic?: string | null, result?: string | null, name?: string | null, script?: string | null, timestamp?: number | null, config?: { __typename?: 'Config', hook?: { __typename?: 'ConfigurationItem', value?: string | null, id: string } | null, retention?: { __typename?: 'ConfigurationItem', id: string, value?: string | null } | null, callToAction?: { __typename?: 'ConfigurationItem', id: string, value?: string | null } | null } | null, speech?: { __typename?: 'Speech', id: string, created?: number | null, expires?: number | null, voice?: { __typename?: 'Voice', description?: string | null, gradient?: string | null, name?: string | null, id: string } | null } | null } };

export type GenerateSpeechMutationVariables = Exact<{
  projectId?: InputMaybe<Scalars['String']['input']>;
  voiceId: Scalars['String']['input'];
}>;


export type GenerateSpeechMutation = { __typename?: 'Mutation', generateSpeech: { __typename?: 'Project', id: string, topic?: string | null, result?: string | null, name?: string | null, script?: string | null, timestamp?: number | null, config?: { __typename?: 'Config', hook?: { __typename?: 'ConfigurationItem', value?: string | null, id: string } | null, retention?: { __typename?: 'ConfigurationItem', id: string, value?: string | null } | null, callToAction?: { __typename?: 'ConfigurationItem', id: string, value?: string | null } | null } | null, speech?: { __typename?: 'Speech', id: string, created?: number | null, expires?: number | null, voice?: { __typename?: 'Voice', description?: string | null, gradient?: string | null, name?: string | null, id: string } | null } | null } };

export type UpdateProjectMutationVariables = Exact<{
  projectId?: InputMaybe<Scalars['String']['input']>;
  params: UpdateParams;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'Project', id: string, topic?: string | null, result?: string | null, name?: string | null, script?: string | null, timestamp?: number | null, config?: { __typename?: 'Config', hook?: { __typename?: 'ConfigurationItem', value?: string | null, id: string } | null, retention?: { __typename?: 'ConfigurationItem', id: string, value?: string | null } | null, callToAction?: { __typename?: 'ConfigurationItem', id: string, value?: string | null } | null } | null, speech?: { __typename?: 'Speech', id: string, created?: number | null, expires?: number | null, voice?: { __typename?: 'Voice', description?: string | null, gradient?: string | null, name?: string | null, id: string } | null } | null } };

export type RemoveProjectMutationVariables = Exact<{
  projectId?: InputMaybe<Scalars['String']['input']>;
}>;


export type RemoveProjectMutation = { __typename?: 'Mutation', removeProject: boolean };

export type GetConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigurationsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, type?: UserType | null }, configurations: { __typename?: 'ConfigurationAlternatives', hooks?: Array<{ __typename?: 'ConfigurationItem', id: string, value?: string | null, description?: string | null } | null> | null, retentions?: Array<{ __typename?: 'ConfigurationItem', id: string, value?: string | null, description?: string | null } | null> | null, callToActions?: Array<{ __typename?: 'ConfigurationItem', id: string, value?: string | null, description?: string | null } | null> | null } };

export type CreateProjectMutationVariables = Exact<{
  topic: Scalars['String']['input'];
  config: CreateConfigParams;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject: { __typename?: 'Project', id: string, topic?: string | null, result?: string | null } };

export type ListProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: string, name?: string | null, script?: string | null, timestamp?: number | null }> };

export const ProjectFieldsFragmentDoc = gql`
    fragment ProjectFields on Project {
  id
  topic
  config {
    hook {
      value
      id
    }
    retention {
      id
      value
    }
    callToAction {
      id
      value
    }
  }
  result
  name
  script
  speech {
    id
    voice {
      description
      gradient
      name
      id
    }
    created
    expires
  }
  timestamp
}
    `;
export const GetLayoutInfoDocument = gql`
    query GetLayoutInfo {
  me {
    type
    name
    username
    tokens
  }
}
    `;

/**
 * __useGetLayoutInfoQuery__
 *
 * To run a query within a React component, call `useGetLayoutInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayoutInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayoutInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLayoutInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetLayoutInfoQuery, GetLayoutInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLayoutInfoQuery, GetLayoutInfoQueryVariables>(GetLayoutInfoDocument, options);
      }
export function useGetLayoutInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLayoutInfoQuery, GetLayoutInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLayoutInfoQuery, GetLayoutInfoQueryVariables>(GetLayoutInfoDocument, options);
        }
export function useGetLayoutInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLayoutInfoQuery, GetLayoutInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLayoutInfoQuery, GetLayoutInfoQueryVariables>(GetLayoutInfoDocument, options);
        }
export type GetLayoutInfoQueryHookResult = ReturnType<typeof useGetLayoutInfoQuery>;
export type GetLayoutInfoLazyQueryHookResult = ReturnType<typeof useGetLayoutInfoLazyQuery>;
export type GetLayoutInfoSuspenseQueryHookResult = ReturnType<typeof useGetLayoutInfoSuspenseQuery>;
export type GetLayoutInfoQueryResult = Apollo.QueryResult<GetLayoutInfoQuery, GetLayoutInfoQueryVariables>;
export const CompleteDocument = gql`
    mutation Complete($params: CompleteParams!) {
  complete(params: $params)
}
    `;
export type CompleteMutationFn = Apollo.MutationFunction<CompleteMutation, CompleteMutationVariables>;

/**
 * __useCompleteMutation__
 *
 * To run a mutation, you first call `useCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMutation, { data, loading, error }] = useCompleteMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCompleteMutation(baseOptions?: Apollo.MutationHookOptions<CompleteMutation, CompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteMutation, CompleteMutationVariables>(CompleteDocument, options);
      }
export type CompleteMutationHookResult = ReturnType<typeof useCompleteMutation>;
export type CompleteMutationResult = Apollo.MutationResult<CompleteMutation>;
export type CompleteMutationOptions = Apollo.BaseMutationOptions<CompleteMutation, CompleteMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  refreshAccessToken {
    token
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String) {
  login(username: $username, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetProjectDocument = gql`
    query GetProject($projectId: String) {
  me {
    id
    type
  }
  voices {
    id
    name
    description
    gradient
    sample {
      duration
    }
  }
  project(id: $projectId) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
        }
export function useGetProjectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectSuspenseQueryHookResult = ReturnType<typeof useGetProjectSuspenseQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GenerateSpeechDocument = gql`
    mutation GenerateSpeech($projectId: String, $voiceId: String!) {
  generateSpeech(projectId: $projectId, voiceId: $voiceId) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export type GenerateSpeechMutationFn = Apollo.MutationFunction<GenerateSpeechMutation, GenerateSpeechMutationVariables>;

/**
 * __useGenerateSpeechMutation__
 *
 * To run a mutation, you first call `useGenerateSpeechMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSpeechMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSpeechMutation, { data, loading, error }] = useGenerateSpeechMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      voiceId: // value for 'voiceId'
 *   },
 * });
 */
export function useGenerateSpeechMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSpeechMutation, GenerateSpeechMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateSpeechMutation, GenerateSpeechMutationVariables>(GenerateSpeechDocument, options);
      }
export type GenerateSpeechMutationHookResult = ReturnType<typeof useGenerateSpeechMutation>;
export type GenerateSpeechMutationResult = Apollo.MutationResult<GenerateSpeechMutation>;
export type GenerateSpeechMutationOptions = Apollo.BaseMutationOptions<GenerateSpeechMutation, GenerateSpeechMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($projectId: String, $params: UpdateParams!) {
  updateProject(id: $projectId, params: $params) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const RemoveProjectDocument = gql`
    mutation RemoveProject($projectId: String) {
  removeProject(id: $projectId)
}
    `;
export type RemoveProjectMutationFn = Apollo.MutationFunction<RemoveProjectMutation, RemoveProjectMutationVariables>;

/**
 * __useRemoveProjectMutation__
 *
 * To run a mutation, you first call `useRemoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectMutation, { data, loading, error }] = useRemoveProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRemoveProjectMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProjectMutation, RemoveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProjectMutation, RemoveProjectMutationVariables>(RemoveProjectDocument, options);
      }
export type RemoveProjectMutationHookResult = ReturnType<typeof useRemoveProjectMutation>;
export type RemoveProjectMutationResult = Apollo.MutationResult<RemoveProjectMutation>;
export type RemoveProjectMutationOptions = Apollo.BaseMutationOptions<RemoveProjectMutation, RemoveProjectMutationVariables>;
export const GetConfigurationsDocument = gql`
    query GetConfigurations {
  me {
    id
    type
  }
  configurations {
    hooks {
      id
      value
      description
    }
    retentions {
      id
      value
      description
    }
    callToActions {
      id
      value
      description
    }
  }
}
    `;

/**
 * __useGetConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
      }
export function useGetConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
        }
export function useGetConfigurationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, options);
        }
export type GetConfigurationsQueryHookResult = ReturnType<typeof useGetConfigurationsQuery>;
export type GetConfigurationsLazyQueryHookResult = ReturnType<typeof useGetConfigurationsLazyQuery>;
export type GetConfigurationsSuspenseQueryHookResult = ReturnType<typeof useGetConfigurationsSuspenseQuery>;
export type GetConfigurationsQueryResult = Apollo.QueryResult<GetConfigurationsQuery, GetConfigurationsQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($topic: String!, $config: CreateConfigParams!) {
  createProject(topic: $topic, config: $config) {
    id
    topic
    result
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      topic: // value for 'topic'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const ListProjectsDocument = gql`
    query ListProjects {
  projects {
    id
    name
    script
    timestamp
  }
}
    `;

/**
 * __useListProjectsQuery__
 *
 * To run a query within a React component, call `useListProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProjectsQuery, ListProjectsQueryVariables>(ListProjectsDocument, options);
      }
export function useListProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProjectsQuery, ListProjectsQueryVariables>(ListProjectsDocument, options);
        }
export function useListProjectsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListProjectsQuery, ListProjectsQueryVariables>(ListProjectsDocument, options);
        }
export type ListProjectsQueryHookResult = ReturnType<typeof useListProjectsQuery>;
export type ListProjectsLazyQueryHookResult = ReturnType<typeof useListProjectsLazyQuery>;
export type ListProjectsSuspenseQueryHookResult = ReturnType<typeof useListProjectsSuspenseQuery>;
export type ListProjectsQueryResult = Apollo.QueryResult<ListProjectsQuery, ListProjectsQueryVariables>;